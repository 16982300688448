import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";

const editSiteGeometry = async (axiosAuth: any, geometryData: any) => {
  const { id, ...rest } = geometryData; 
  const response = await axiosAuth.put(
    `${process.env.REACT_APP_API_BASE_URL}site_geometry/${id}/`,
    rest
  );
  return response.data;
};

export function useEditSiteGeometry() {
  const axiosAuth = useAxiosAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (geometryData: any) => {
      return editSiteGeometry(axiosAuth, geometryData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["site_geometry"]); // ensure cache is refreshed
    },
    onError: (error) => {
      console.error("editSiteGeometry mutation failed", error);
    },
  });
}